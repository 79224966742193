export type SortDirection = 'asc' | 'desc';

export const getNextDirection = (dir?: SortDirection): SortDirection => {
  return !dir || dir === 'asc' ? 'desc' : 'asc';
};

export const reverseDirection = (dir: SortDirection): SortDirection =>
  dir === 'asc' ? 'desc' : 'asc';

export type SortFn<SortKey> = (
  nextSortBy: SortKey,
  nextDirection: SortDirection
) => void;

export type Sorting<T> = {
  sortBy: T;
  direction: SortDirection;
  setSort: SortFn<T>;
};

export const getInfinityNumber = (dir: SortDirection) =>
  dir === 'asc' ? Infinity : -Infinity;
