import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { partition } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Edit, Trash } from 'react-feather';
import { Menu } from '../../../components/AdminMenu';
import {
  IColumn,
  ID_TO_ROW_KEY,
  ItemSorter,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS
} from '../../../components/GroupableList';
import { LinkExternal } from '../../../components/LinkExternal';
import { PlainLoader } from '../../../components/Loader';
import { ProductHuntBanner } from '../../../components/ProductHuntBanner';
import { onlyForAdmins } from '../../../components/ProtectedAdminPage';
import { SEO } from '../../../components/SEO';
import { SubmitBlogForm } from '../../../components/SubmitBlogDialog';
import { useErrorLogger } from '../../../hooks/useErrorLogger';
import { useSnackbar } from '../../../hooks/useSnackbar';
import Layout, { Wrapper } from '../../../layouts/Layout';
import { EMPTY_ARR } from '../../../services/emptyConstants';
import { SortDirection } from '../../../services/sort';
import {
  deleteSubmission,
  updateSubmission,
  useSubmissions
} from '../../../services/submission';
import { now } from '../../../services/time';
import styled from '../../../styled';
import { Doc } from '../../../types/Document';
import { Submission } from '../../../types/Submission';
import { deleteMonitoredBlog } from '../../../services/admin-rankings';

const Body = styled('div')`
  margin: ${(p) => p.theme.spacing(4)}px;
`;

export const CanvasBar = styled('div')((p) => ({
  marginBottom: p.theme.spacing() * 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  color: p.theme.palette.text.secondary,
  fontSize: p.theme.custom.fontSize.m
}));

const Section = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

type D = Doc<Submission>;
type ColumnName = 'name' | 'url' | 'topics' | 'createdAt' | 'actions';
type OtherProps = {
  modals: {
    accept: {
      open: (d: D) => void;
      close: () => void;
    };
    edit: {
      open: (d: D) => void;
      close: () => void;
    };
  };
};
type Column = IColumn<D, ColumnName, OtherProps>;

const SubmissionEditDialog = ({
  open,
  onClose,
  d
}: {
  open: boolean;
  onClose: () => void;
  d: D;
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Submission</DialogTitle>

      <DialogContent>
        <SubmitBlogForm
          value={d.data}
          onSubmit={(x) =>
            updateSubmission(d.id, {
              ...x,
              createdAt: d.data.createdAt
            })
          }
          mode="edit"
          onCancel={onClose}
          label="Edit submission"
        />
      </DialogContent>
    </Dialog>
  );
};

const SubmissionAcceptDialog = ({
  open,
  onClose,
  d
}: {
  open: boolean;
  onClose: () => void;
  d: D;
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Accept Submission</DialogTitle>

      <DialogContent>
        <SubmitBlogForm
          value={d.data}
          onDelete={() => {
            return deleteSubmission(d.id);
          }}
          onSubmit={(x) =>
            updateSubmission(d.id, {
              ...x,
              createdAt: d.data.createdAt,
              status: 'ACCEPTED',
              acceptedAt: now()
            })
          }
          mode="edit"
          onCancel={onClose}
          label="Update & accept"
        />
      </DialogContent>
    </Dialog>
  );
};

const ActionsContainer = styled('div')((p) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '> :not(:first-child)': {
    marginLeft: p.theme.spacing()
  }
}));

const Actions = ({ d, o }: { d: D; o: OtherProps }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <ActionsContainer>
      {d.data.status === 'AWAITING_APPROVAL' && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => o.modals.accept.open(d)}
        >
          Accept
        </Button>
      )}
      <IconButton onClick={() => o.modals.edit.open(d)}>
        <Edit size={16} />
      </IconButton>

      <IconButton
        onClick={() => {
          if (d.data.status === 'AWAITING_APPROVAL') {
            return deleteSubmission(d.id).then(() => {
              return enqueueSnackbar('Deleted submission', {
                variant: 'success'
              });
            });
          }
          if (d.data.status === 'ACCEPTED') {
            return deleteMonitoredBlog(d.id).then(() => {
              return enqueueSnackbar('Deleted blog', {
                variant: 'success'
              });
            });
          }
        }}
      >
        <Trash size={16} />
      </IconButton>
    </ActionsContainer>
  );
};

const COLUMNS: Column[] = [
  {
    key: 'name',
    head: () => 'Name',
    cell: (d) => d.data.name,
    sortable: true,
    align: 'left',
    width: 150,
    flexGrow: 2
  },
  {
    key: 'url',
    head: () => 'URL',
    cell: (d) => <LinkExternal href={d.data.url} />,
    sortable: true,
    align: 'left',
    width: 150,
    flexGrow: 2
  },
  {
    key: 'topics',
    head: () => 'Topics',
    cell: (d) => d.data.topics.join(', '),
    sortable: false,
    align: 'left',
    width: 150,
    flexGrow: 2
  },
  {
    key: 'createdAt',
    head: () => 'created at',
    cell: (d) => d.data.createdAt.toDate().toLocaleDateString(),
    sortable: true,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'actions',
    head: () => '',
    cell: (d, o) => <Actions d={d} o={o} />,
    sortable: false,
    align: 'left',
    width: 130,
    flexGrow: 0
  }
];
const SORTERS: ItemSorters<D> = {
  name: {
    key: 'name',
    items: { sort: (d) => d.data.name, dir: 'asc' }
  },
  url: {
    key: 'email',
    items: { sort: (d) => d.data.url, dir: 'asc' }
  },
  createdAt: {
    key: 'createdAt',
    items: { sort: (d) => d.data.createdAt.toMillis(), dir: 'desc' }
  }
};
const DEFAULT_SORTER = SORTERS.createdAt;

const AdminSubmissionsPage = () => {
  const [ds, loading, error] = useSubmissions();
  useErrorLogger(error);

  const [search, setSearch] = useState('');
  const [submissionToEdit, setSubmissionToEdit] = useState<D | null>(null);
  const [submissionToAccept, setSubmissionToAccept] = useState<D | null>(null);

  const [sortState, setSortState] = useState<{
    sorter: ItemSorter<D>;
    dir: SortDirection;
  }>({
    sorter: DEFAULT_SORTER,
    dir: DEFAULT_SORTER.items.dir
  });

  const [awaitingApproval, accepted] = useMemo(() => {
    if (!ds) {
      return [EMPTY_ARR, EMPTY_ARR];
    }
    return partition(ds, (d) => d.data.status === 'AWAITING_APPROVAL');
  }, [ds]);

  const newRows = awaitingApproval.filter((row) =>
    search.length > 0 ? row.data.url.indexOf(search) !== -1 : true
  );
  const oldRows = accepted.filter((row) =>
    search.length > 0 ? row.data.url.indexOf(search) !== -1 : true
  );

  const otherProps: OtherProps = {
    modals: {
      accept: {
        open: (d) => setSubmissionToAccept(d),
        close: () => setSubmissionToAccept(null)
      },
      edit: {
        open: (d) => setSubmissionToEdit(d),
        close: () => setSubmissionToEdit(null)
      }
    }
  };

  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        title="Admin"
        siteUrl="https://bloggingfordevs.com"
        description="Admin section, lookout!"
        pathname="admin/rankings/submissions"
        noIndex
      />
      <Wrapper>
        <Menu />
        <div
          style={{
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Rankings Submissions
          </Typography>
        </div>
      </Wrapper>
      <Body>
        {loading && <PlainLoader height={500} />}
        {ds && (
          <>
            <Section>
              <CanvasBar>
                <TextField
                  variant="outlined"
                  name="search"
                  placeholder="Search URL"
                  style={{ width: '300px' }}
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                &nbsp;
                <div>
                  <Typography variant="body1" color="textSecondary">
                    Remaining submissions: {newRows.length}
                  </Typography>
                </div>
              </CanvasBar>
              <RowsRenderer
                variant="contained"
                columns={COLUMNS}
                rows={newRows}
                rowToKey={ID_TO_ROW_KEY}
                sorter={sortState.sorter}
                sortDirection={sortState.dir}
                renderHead={true}
                onHeadClick={(h, nextDir) => {
                  const nextSorter = SORTERS[h.key];
                  if (nextSorter) {
                    setSortState({
                      sorter: nextSorter,
                      dir: nextDir || nextSorter.items.dir
                    });
                  }
                }}
                chunkSize={30}
                rootMargin="400px"
                otherProps={otherProps}
                rowHeight={ROW_HEIGHTS.dense}
              />
            </Section>
            <Section>
              <CanvasBar>
                <Typography variant="h4">Accepted</Typography>
              </CanvasBar>
              <RowsRenderer
                variant="contained"
                columns={COLUMNS}
                rows={oldRows}
                rowToKey={ID_TO_ROW_KEY}
                sorter={DEFAULT_SORTER}
                sortDirection={DEFAULT_SORTER.items.dir}
                renderHead={true}
                chunkSize={30}
                rootMargin="400px"
                otherProps={otherProps}
                rowHeight={ROW_HEIGHTS.dense}
              />
            </Section>
          </>
        )}
      </Body>

      {submissionToEdit && (
        <SubmissionEditDialog
          open={true}
          onClose={otherProps.modals.edit.close}
          d={submissionToEdit}
        />
      )}
      {submissionToAccept && (
        <SubmissionAcceptDialog
          open={true}
          onClose={otherProps.modals.accept.close}
          d={submissionToAccept}
        />
      )}
    </Layout>
  );
};

export default onlyForAdmins(AdminSubmissionsPage);
