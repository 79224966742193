import { useEffect, useRef, useState } from 'react';

export const useOnVisbibilityChange = <T extends Element = Element>(
  onVisibilityChange: (visible: boolean) => void,
  options: IntersectionObserverInit = {}
) => {
  const ref = useRef<T>(null!);

  const { root, rootMargin, threshold } = options;

  useEffect(() => {
    if (ref.current && typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          const visibleNow = entries.some((entry) => entry.isIntersecting);
          onVisibilityChange(visibleNow);
        },
        {
          root,
          rootMargin,
          threshold
        }
      );
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [ref, root, rootMargin, threshold, onVisibilityChange]);

  return ref;
};

export const useIsVisible = <T extends Element = Element>(
  options: IntersectionObserverInit = {}
) => {
  const [isInViewport, setIsInViewport] = useState(false);
  const [isInForeground, setIsInForeground] = useState(
    document.visibilityState === 'visible'
  );
  const ref = useOnVisbibilityChange<T>(setIsInViewport, options);

  useEffect(() => {
    const handler = () => {
      setIsInForeground(document.visibilityState === 'visible');
    };
    document.addEventListener('visibilitychange', handler);
    return () => document.removeEventListener('visibilitychange', handler);
  }, []);

  return { isVisible: isInViewport && isInForeground, ref };
};
