import React, { createElement } from 'react';
import styled from '../styled';

const withStoppedPropagation = <T extends any>(
  fn: (e: React.SyntheticEvent) => T,
  preventDefault = true
): ((e: React.SyntheticEvent) => T) => {
  return (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (preventDefault) {
      e.preventDefault();
    }
    return fn(e);
  };
};

const UnderlinedA = styled('a')`
  border-bottom: 1px solid;
`;

export const LinkExternal: React.FC<{
  href: string;
  title?: string;
  className?: string;
  underline?: boolean;
}> = ({ href, title, children, className, underline }) => {
  return createElement(
    underline ? UnderlinedA : 'a',
    {
      className,
      href,
      target: '_blank',
      rel: 'noopener noreferrer',
      title: title || href,
      onClick: withStoppedPropagation(() => {}, false)
    },
    children || href
  );
};

export const LinkExternalWithEllipsis = styled(LinkExternal)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
